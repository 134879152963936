export default {
  BUTTON_PRIMARY_CLASS: 'cm_btn button button--small button--primary',
  BUTTON_SECONDARY_CLASS: 'cm_btn button button--small button--secondary',

  SEARCH_BOX_PLACEHOLDER: '{window.Convermax.config?.searchBoxPlaceholderText || "Search..."}',

  SEARCH_BOX_CLASS: 'search-bar',
  SEARCH_BOX_FORM_CLASS: 'search-bar__top',
  SEARCH_BOX_INPUT_CLASS: 'search-bar__input snize-input-style',
  SEARCH_BOX_BUTTON_CLASS: 'search-bar__submit',

  FACET_PANEL_CLASS: 'card card__section card__section--tight',
  PRODUCT_REPEATER_CLASS: 'product-list product-list--collection product-list--with-sidebar card',
  PRODUCT_ITEM_CLASS: 'product-item product-item--{this.view} 1/3--tablet-and-up 1/4--desk',

  GARAGE_BUTTON_CLASS: 'header__action-item-link header__cart-toggle',

  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',

  VEHICLE_CATEGORY_REPEATER_CLASS: 'cm_vehicle-categories_category-wrapper-repeater',

  CLEAR_TEXT: '<rt-virtual template="icons/reset" />',

  FILTERS_TEXT: `{window.themeSettings?.locales.mobile_filter_button || 'Filters'}`,
};
